.breakout-game {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
}

.breakout-game canvas {
  border: 2px solid #00ff00;
  background: #000;
}

.breakout-game button {
  padding: 0.5rem 1rem;
  background: #00ff00;
  border: none;
  color: #000;
  cursor: pointer;
  font-weight: bold;
}

.breakout-game button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.game-over {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.9);
  padding: 2rem;
  border: 2px solid #00ff00;
  text-align: center;
  color: #00ff00;
}

.canvas-container {
  position: relative;
  width: fit-content;
  margin: 0 auto;
}

.game-over-overlay,
.start-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 8px;
  color: #00ff00;
}

.game-over-overlay button,
.start-overlay button {
  background: #00ff00;
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.game-over-overlay button:hover,
.start-overlay button:hover {
  background: #00dd00;
}
