body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'VT323', monospace;
  font-size: 20px; /* Increased from 18px */
}

#root {
  height: 100%;
  width: 100%;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.app-header {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: inherit;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-title {
  display: flex;
  align-items: center;
}

.app-logo {
  height: 50px;
  margin-right: 10px;
}

.dark-mode-toggle-container {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1000;
}

.dark-mode-toggle {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 24px;
  min-width: 44px;
  min-height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(240, 240, 240, 0.7);
  border-radius: 50%;
}

.dark-mode .dark-mode-toggle {
  background-color: rgba(0, 0, 0, 0.7);
}

/* Remove these styles if they exist */
/* .dark-mode-toggle {
  position: fixed;
  cursor: move;
  z-index: 1000;
} */

.app-content {
  flex-grow: 1;
  padding: 1rem;
  overflow-y: auto;
}

.app-footer {
  position: sticky;
  bottom: 0;
  z-index: 1000;
  background-color: inherit;
  padding: 1rem;
}

.dark-mode-toggle {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.social-links a {
  color: inherit;
}

/* Responsive styles */
@media (max-width: 768px) {
  .app-header {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 3rem; /* Add space for the dark mode toggle */
  }

  .logo-title {
    width: 100%;
    justify-content: center;
  }

  .logo-title h1 {
    font-size: 1.2rem;
  }

  .app-logo {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  .dark-mode-toggle-container {
    top: 5px;
    right: 5px;
  }

  h1 {
    font-size: 1.5rem;
  }

  .app-content {
    padding: 10px;
  }
}

/* Dark mode styles */
.dark-mode {
  background-color: #000000;
  color: #00ff00;
}

.light-mode {
  background-color: #f0f0f0;
  color: #000000;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Press Start 2P', cursive;
  letter-spacing: 2px;
}

input, button {
  margin: 10px 0;
  padding: 12px; /* Increased from 10px */
  font-size: 18px; /* Increased from 16px */
  border-radius: 0; /* Remove border-radius for a more retro look */
}

button {
  font-family: 'Press Start 2P', cursive;
  font-size: 16px; /* Increased from 14px */
  padding: 12px 24px; /* Increased padding */
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 3px 3px 0px #45a049;
}

button:hover {
  background-color: #45a049;
}

button:active {
  box-shadow: 1px 1px 0px #45a049;
  transform: translate(2px, 2px);
}

.dark-mode button {
  background-color: #00aa00;
  color: #000000;
  box-shadow: 3px 3px 0px #008800;
}

.dark-mode button:hover {
  background-color: #008800;
}

.dark-mode button:active {
  box-shadow: 1px 1px 0px #008800;
}

input {
  font-family: 'VT323', monospace;
  font-size: 20px; /* Increased from 18px */
  padding: 12px; /* Increased from 10px */
  border: 2px solid #000000;
  background-color: #ffffff;
  color: #000000;
}

.dark-mode input {
  background-color: #000000;
  color: #00ff00;
  border: 2px solid #00ff00;
}

a {
  color: #0000ff;
  text-decoration: none;
}

.dark-mode a {
  color: #00ffff;
}

.error {
  color: #ff0000;
}

.dark-mode .error {
  color: #ff6666;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f0f0f0;
  color: #000000;
  padding: 20px;
  border: 4px solid #000000;
  z-index: 1000;
  box-shadow: 5px 5px 0px #000000;
}

.dark-mode .popup {
  background-color: #000000;
  color: #00ff00;
  border: 4px solid #00ff00;
  box-shadow: 5px 5px 0px #00ff00;
}

/* Add some retro-style borders */
.wallet-connection, .deposit-form, .apr-calculator, .earnings-display, .liquidate-equity, .transaction-history {
  border: 2px solid #000000; /* Reduced from 4px */
  padding: 15px; /* Reduced from 20px */
  margin-bottom: 15px; /* Reduced from 20px */
  background-color: #f0f0f0;
  max-width: 600px; /* Add max-width to prevent stretching */
  margin-left: auto;
  margin-right: auto;
}

.dark-mode .wallet-connection,
.dark-mode .deposit-form,
.dark-mode .apr-calculator,
.dark-mode .earnings-display,
.dark-mode .liquidate-equity,
.dark-mode .transaction-history {
  border: 2px solid #00ff00; /* Reduced from 4px */
  background-color: #000000;
}

/* Tooltip styles */
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  font-family: 'VT323', monospace;
  font-size: 16px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* Dark mode tooltip */
.dark-mode .tooltip .tooltiptext {
  background-color: #00ff00;
  color: #000;
}

/* Add some retro-style borders */
.wallet-connection, .deposit-form, .apr-calculator, .earnings-display, .liquidate-equity, .transaction-history {
  border: 2px solid #000;
  padding: 15px;
  margin-bottom: 15px;
  background-color: #f0f0f0;
}

/* Adjust heading sizes for retro look */
h1 {
  font-size: 28px; /* Increased from 24px */
}

h2 {
  font-size: 24px; /* Increased from 20px */
}

/* ... rest of the styles ... */
