.whackamole-game {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.game-info {
  text-align: center;
  margin-bottom: 20px;
}

.mole-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  max-width: 400px;
}

.mole-hole {
  background-color: #8b4513;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.mole {
  width: 80px;
  height: 80px;
  background-color: #ebe3db;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  transition: bottom 0.1s;
}

.mole-up .mole {
  bottom: 30px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
}

button:hover {
  background-color: #45a049;
} 