.games-section {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.game-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.game-buttons button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.game-buttons button.active {
  background-color: #ff6b6b;
  box-shadow: 3px 3px 0px #ff5252;
}

.game-container {
  border: 2px solid #000;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.dark-mode .game-container {
  border-color: #00ff00;
}

.game-selector {
  margin-bottom: 20px;
}

.game-dropdown {
  padding: 10px;
  font-size: 16px;
  font-family: 'Press Start 2P', cursive;
  background-color: #f0f0f0;
  border: 2px solid #000;
  cursor: pointer;
  min-width: 200px;
}

.dark-mode .game-dropdown {
  background-color: #000;
  color: #00ff00;
  border-color: #00ff00;
}

.game-dropdown option {
  padding: 10px;
}

.dark-mode .game-dropdown option {
  background-color: #000;
  color: #00ff00;
}

@media (max-width: 768px) {
  .games-section {
    padding: 10px;
  }
  
  .game-container {
    min-height: 300px;
  }
  
  .game-dropdown {
    width: 100%;
    max-width: 300px;
  }
}

.claim-button {
  margin-top: 6px;
  padding: 4px 12px;
  background-color: #00ff00;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 8px;
  font-weight: bold;
  transition: background-color 0.3s;
  width: auto;
  align-self: center;
}

.claim-button:hover {
  background-color: #00cc00;
}

.claim-button:disabled {
  background-color: #666;
  cursor: not-allowed;
}

.claim-earnings-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #000;
  color: #00ff00;
  border: 2px solid #00ff00;
  font-family: 'Press Start 2P', cursive;
  cursor: pointer;
  font-size: 16px;
  text-transform: uppercase;
}

.claim-earnings-button:hover {
  background-color: #00ff00;
  color: #000;
}

.dark-mode .claim-earnings-button {
  background-color: #000;
  color: #00ff00;
  border-color: #00ff00;
}

.dark-mode .claim-earnings-button:hover {
  background-color: #00ff00;
  color: #000;
}
