.snake-game {
  text-align: center;
  touch-action: none;
}

.snake-game canvas {
  border: 2px solid #000;
  margin: 20px auto;
  max-width: 100%;
  touch-action: none;
}

.dark-mode .snake-game canvas {
  border-color: #00ff00;
}

.game-over {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 20px;
  border-radius: 10px;
}

