.terms-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.terms-modal {
  background-color: #f0f0f0;
  color: #000000;
  border: 4px solid #000;
  padding: 20px;
  width: 90%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
  font-family: 'VT323', monospace;
  font-size: 18px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #000000;
  font-size: 24px;
  cursor: pointer;
  font-family: 'Press Start 2P', cursive;
}

.terms-modal h2 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 20px;
  font-family: 'Press Start 2P', cursive;
}

.terms-content {
  margin-bottom: 20px;
}

.terms-content p {
  margin-bottom: 15px;
  line-height: 1.5;
}

.terms-content a {
  color: #0000FF;
  text-decoration: none;
}

.terms-content a:hover {
  text-decoration: underline;
}

.accept-button {
  font-family: 'Press Start 2P', cursive;
  font-size: 14px;
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  box-shadow: 3px 3px 0px #45a049;
}

.accept-button:hover {
  background-color: #45a049;
}

.accept-button:active {
  box-shadow: 1px 1px 0px #45a049;
  transform: translate(2px, 2px);
}